.image-slider-container {
  @include spacing(4);
  margin: -0.375rem;
  padding: 0.375rem;
  overflow: hidden;

  &:first-child,
  &:last-child {
    margin: -0.375rem !important;
  }
}

.image-slider {
  margin: 0;
  padding: 0;
  .flickity-viewport {
    overflow: visible;
    @include mq(64.0625em) {
      overflow: hidden;
    }
  }

  .flickity-page-dots {
    top: 1rem;
    right: 1rem;
    @include mq(48em) {
      top: 2.5rem;
      right: 2.5rem;
    }
    @include mq(64.0625em) {
      display: none;
    }
  }

  .flickity-prev-next-button {
    display: none;
    @include mq(64.0625em) {
      display: block;
      filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="0" /><feOffset dx="1" dy="1" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,1)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
      filter: drop-shadow(0 .1rem .1rem $black);
      filter: progid:DXImageTransform.Microsoft.Shadow(color=#000,strength=0);
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background: rgba($black, .54);
      }
      .arrow {
        fill: $white;
      }
    }
  }
}

.image-slider--full {
  .flickity-viewport {
    @include mq(64.0625em) {
      overflow: visible;
    }
  }
}

.image {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;

  &:hover,
  &:focus-within {
    .image__overlay {
      transform: translateY(0);
    }

    .image__social {
      opacity: 1;
    }
  }

  &:focus {
    outline-offset: 0;
    .image__overlay {
      transform: translateY(0);
    }

    .image__social {
      opacity: 1;
    }
  }

  .image-slider & {
    margin: 0 0.625rem;
    box-shadow: 0 0 0.375rem $black-shadow;
    @include mq(64.0625em) {
      width: 50%;
      margin: 0;
      box-shadow: none;
    }
  }
  .image-slider--full & {
    margin: 0 0.625rem;
    box-shadow: 0 0 0.375rem $black-shadow;
    @include mq(64.0625em) {
      width: 100%;
      // margin: 0;
      // box-shadow: none;
    }
  }
}

.image--social {
  position: relative;
  width: 15rem;
  max-height: 15rem;
}

.image__item {
  min-height: 10rem;
  .image--social & {
    min-height: 15rem;
    object-fit: cover;
  }
}

.image__social {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding: 1rem;
  transition: opacity $transition;
  background: $black-fade;
  color: $white;
  font-family: $sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  opacity: 0;
  .image--social.focus-within & {
    opacity: 1;
  }
}

.image__date {
  width: 100%;
}

.image__desc {
  width: 100%;
  margin: auto 0;
  max-height: 10rem;
  overflow: hidden;
  a {
    color: $white;
  }
}

.image__meta {
  @include clear;
  width: 100%;
  margin-top: auto;
}

.image__meta__link {
  margin-right: 0.5rem;
  float: left;
  color: $white;
  overflow: hidden;
}

.image__meta__icon {
  width: 0.75rem;
  margin-top: 0.1875rem;
  margin-right: 0.25rem;
  float: left;
}

.image__overlay {
  @include zero;
  padding: 1.25rem;
  background: $blue;
  color: $white;
  .image--social.focus-within & {
    transform: translateY(0);
  }
  @include mq(64.0625em) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%) translateY(-4rem);
    transition: transform $transition;
  }
}

.image__gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-image: linear-gradient(transparent, $black-fade);

  .image-slider & {
    display: none;
    @include mq(64.0625em) {
      display: block;
    }
  }
  .image-slider--full & {
    @include mq(64.0625em) {
      display: none;
    }
  }
}

.image__heading {
  color: $white;
}

.image__heading--link {
  font-weight: 400;
  .image-slider & {
    @include mq($max:1024px) {
      color: $blue;

    }
  }

  .image:hover & {
    text-decoration: underline;
  }
}

.image__text {
  @include zero;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.25rem;
  color: $white;
  text-shadow: .1rem .1rem .1rem $black;

  .image-slider & {
    position: static;
    padding: 2rem 1rem;
    background: $white;
    color: $black;
    text-shadow: none;
    // @include mq(48em) {
      //   padding: 5rem 2.5rem;
      // }

      @include mq(64.0625em) {
        position: absolute;
        padding: 1.25rem;
        background: transparent;
        color: $white;
        text-shadow: .1rem .1rem .1rem $black;
      }
    }
    .image-slider--full & {
      @include mq(64.0625em) {
        position: static;
        text-shadow: none;
        color: inherit;
        background: inherit;
      }
    }
  }


  .image__box {
    background: $white;
    padding: 1.25rem;
  }
