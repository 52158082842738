.hero-container {
  @include zero;
  position: relative;
  padding-top: .1rem;
  .flickity-enabled:focus {
    @include focus;
    outline-offset: 0;
    z-index: 3;
  }
}

.hero-slider {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
  }
  .flickity-page-dots {
    top: 0;
    right: 0;
    width: 100%;
    margin-top: calc(46.0417% - 2rem);

    @include mq(64.0625em) {
      top: 50%;
      right: 4rem;
      left: auto;
      width: auto;
      margin: 0;
      transform: translateY(-50%);
    }

    @include mq(90em) {
      right: 5rem;
    }

    .dot {
      color: $white;
filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="0" /><feOffset dx="1" dy="1" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,1)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
      filter: drop-shadow(0 .1rem .1rem $black);
      filter: progid:DXImageTransform.Microsoft.Shadow(color=#000,strength=0);
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background: rgba($black, .54);
      }
      @include mq(64.0625em) {
        display: block;
        margin: 0.5rem 0;
      }
    }
  }
}

.hero {
  @include zero;
  position: relative;
  width: 100%;
  list-style: none;
  display: block;
}

.hero__image {
  position: relative;
  color: $white;

  img {
    width: 100%;
  }
}

.hero__gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-image: linear-gradient(transparent, $black-fade);
}

.hero__gradient-alt {
  height: 30%;
}

.hero__wrap {
  position: relative;
}

.hero__content {
  @include zero;
  margin: 0 -1rem;
  padding: 2.5rem 1rem;
  background: $beige;
  display: block;

  @include mq(48em) {
    margin: 0 -2rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @include mq(64.0625em) {
    position: absolute;
    bottom: 2rem;
    width: calc(66.6667% - 2rem);
    margin: 0;
    padding: 0;
    transition: opacity $transition-easing $transition-speed * 2;
    background: transparent;
    color: $white;
    opacity: 0;
    text-shadow: .1rem .1rem .1rem $black;
    left: 3rem;
    .is-selected & {
      transition-delay: $transition-speed;
      opacity: 1;
    }
  }
  @include mq(90em) {
    left: 2rem;
    bottom: 3rem;
    width: calc(50% - 3rem);
  }
}

a.hero__content {
  display: block;
  &:hover, &:focus {
    text-decoration: none;
  }
}

.hero__content--alt {
  opacity: 1;
}

.hero__title {
  position: absolute;
  bottom: 1rem;
  margin: 0;
  color: $white;
  text-shadow: .1rem .1rem .1rem $black;

  @include mq(48em) {
    bottom: 2rem;
  }
  @include mq(90em) {
    bottom: 3rem;
  }

  a {
    color: inherit;
  }
}

.hero__title--sized {
  @extend .hero__title;
  @include mq($max: 48em) {
    position: static;
    color: $black-light;
    text-shadow: none;
    margin-top: 1rem;
  }
}

.hero__heading {
  display: block;

  @include spacing;
  font-family: $sans-serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.25;

  @include mq(64.0625em) {
    font-size: 2.75rem;
    color: $white;
  }

  // @include mq(90em) {
  //   font-size: 3.5rem;
  // }
}

.hero__text {
  display: block;
  @include spacing;
  font-size: 1.25rem;
  @include mq(64.0625em) {
    color: $white;
  }
}

.hero__button {
  @include mq(64.0625em) {
    color: $white;
  }
}

.hero--video {
  @include mq(48em) {
    // height: 800px;
  }

  video {
    display: none;
    @include mq(48em) {
      display: block;
    }
  }
  img {
    display: block;
    @include mq(48em) {
      display: none;
    }
  }
}

.hero__video {
  display: none;

  width: 100%;
  height: 100%;
  background-color: $black;
  overflow: hidden;
  z-index: -1;

  @include mq(48em) {
    display: block;
  }
  video {
    width: 100%;
    @supports (object-fit: cover) {
      height: 100%;
      object-fit: cover;
    }
    &::-webkit-media-controls {
      display: none !important;
    }

    &::-webkit-media-controls-start-playback-button {
      display: none !important;
      appearance: none;
    }
  }
}
