.form {
  @include zero;
  font-family: $sans-serif;
  font-size: 1rem;
  font-weight: 500;

  .form_action button {
    @extend .button;
    margin-bottom: 0;
  }
}

.form__input,
.form_text input,
.form_address textarea,
.form_responses input[type="text"],
.form_responses input[type="email"],
.form_responses input[type="tel"] {
  display: block;
  width: 100%;
  height: 3rem;
  margin-top: 0.5rem;
  padding: 0.75rem;
  border: 0.0625rem solid $black-light;
  background: $white;
  line-height: normal;

  box-shadow: inset 0 1px 3px rgba(0,0,0,.3);
  border-radius: 0.1875rem;

  &::placeholder {
    color: inherit;
  }

  &:focus {
    box-shadow: none;
  }
}


.form__textarea {
  @extend .form__input;
  height: auto;
  min-height: 5rem;
}

.form__req {
  color: #FF0200;
}

.form_multiselect .form_responses {
  font-size: .8rem;
  margin-top: 0.5rem;
  border: 0.0625rem solid $black-light;
  background: $white;
  line-height: normal;

  box-shadow: inset 0 1px 3px rgba(0,0,0,.3);
  border-radius: 0.1875rem;
  &::placeholder {
    color: inherit;
  }

  &:focus {
    box-shadow: none;
  }
}

.form__select {
  position: relative;
  margin-top: 0.5rem;

  select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    opacity: 0;
  }

  option {
    font-weight: 500;
  }
}

.form__select__text {
  width: 100%;
  height: 3rem;
  padding: 1rem 3rem 1rem 0.75rem;
  border: 0.0625rem solid;
  border-radius: 0.1875rem;
  color: $black;
  font-family: $sans-serif;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: normal;
}

.form__select__text--focus {
  @include focus;
}

.form__select__arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
  padding: 1rem;
}

$focusGray: #707070;
$focusBlue: #2572e6;
$radio-size: 1.2em;
.form__radio,
.form_radio {
  input[type="radio"] {
    position: absolute;
    border: 0;
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0px, 0px, 0px, 0px);
  }

  label {
    position: relative;
    display: inline-block;
    margin-left: 2px;
    padding-left: calc(#{$radio-size} + 10px);
    height: $radio-size;
  }

  input[type="radio"] + label:before {
    position: absolute;
    top: 0;
    left: 0;
    width: $radio-size;
    height: $radio-size;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }



  input[type="radio"] + label:before {
    content: '\a0';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -0.5rem;
    height: $radio-size;
    width: $radio-size;
    border: 1px solid $focusGray;
    border-radius: 50%;
    box-shadow: inset 0 1px 3px rgba(0,0,0,.3);
    background: white;
  }

  input[type="radio"]:checked + label::before {
    background: $focusBlue;
    background-size: 150%;
    box-shadow: inset 0 0 0 .125em #fff;
  }

  input[type="radio"]:focus + label::before {
    @include focus;
  }

}


.form__checkbox,
.form_checkbox {
  margin: 0.5rem 0;
  label {
    position: relative;
    display: inline-block;
    margin-left: 2px;
    padding-left: calc(#{$radio-size} + 10px);
    // height: $radio-size;
  }

  input[type="checkbox"] {
    position: absolute;
    border: 0;
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0px, 0px, 0px, 0px);
  }
  input[type="checkbox"] + label:before,
  input[type="checkbox"] + label:after {
    position: absolute;
    top: 0;
    left: 0;
    width: $radio-size;
    height: $radio-size;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  input[type="checkbox"] + label:before {
    content: '\a0'; // simulates a non-breaking space: http://stackoverflow.com/questions/190396/adding-html-entities-using-css-content
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -0.5rem; //half height and border to vertically align
    height: $radio-size;
    width: $radio-size;
    border: 1px solid $focusGray;
    box-shadow: inset 0 1px 3px rgba(0,0,0,.3);
    background: white;
  }

  input[type="checkbox"]:checked + label::before {
    background: $focusBlue;
    // background-size: 150%;
    box-shadow: inset 0 0 0 .125em #fff;
  }

  input[type="checkbox"]:checked + label::after {
    position: absolute;
    content: '\a0';
    left: .4em;
    top: 5px;
    width: 6px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  input[type="checkbox"]:focus + label::before {
    @include focus;
  }
}


.form_responses {
  margin-bottom: 1rem;
}

.form_select,
.form_address,
.form_responses {
  select {
    font-family: $sans-serif;
    display: block;
    width: 100%;
    height: 3rem;
    margin-top: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 3rem;
    border: 0.0625rem solid $blue !important;
    color: $black;

    line-height: normal;
    font-size: 0.9375rem;
    border-radius: 0.1875rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    @include fill($svgstore--chevron-down, $blue);
    background-color: $white;
    background-repeat: no-repeat;
    background-position: calc(100% - .75rem) 50%;
    background-size: 1rem 1rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
  option {
  }
}

.form_responses {
  select[multiple] {
    height: auto;
    background: none;
    border: 0 !important;
    padding-bottom: 0.75rem;
    padding-right: 0.75rem;
  }
}

.form_question {
  margin-top: 2rem;
  .hero & {
    margin-top: 0.5rem;
  }
  legend {
    @include hide;
  }
}

.form_birthdate {
  select {
    font-family: $sans-serif;

    width: auto;
    display: inline-block;
    height: 3rem;
    margin-top: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 3rem;
    border: 0.0625rem solid $blue;
    color: $black;

    line-height: normal;
    font-size: 0.9375rem;
    border-radius: 0.1875rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    @include fill($svgstore--chevron-down, $blue);
    background-color: $white;
    background-repeat: no-repeat;
    background-position: calc(100% - .75rem) 50%;
    background-size: 1rem 1rem;
    appearance: none;
    .hero & {
      width: 100%;
      margin-left: 0 !important;
    }
  }
  option {

  }
}

.module__container {

  .form_page_1 {
    @include mq(737px) {
      // Client requested flex be removed from container 2/18/2021
      //display: flex;
      //flex-wrap: wrap;
      margin-left: -1rem;
    }

    .form_question {
      display: block !important;
      float: none !important;
      @include mq(737px) {
        margin-left: 1rem !important;
        width: calc(50% - 1rem) !important;
      }

      .form_label {
        padding: 0 !important;
        display: block !important;
        width: auto !important;
      }
    }
    .form_question.form_h2{
      @include mq(737px) {
        width: 100%;
      }
    }
    .form_responses {
      display: block !important;
      padding: 0 !important;
    }
  }


  .form_action button {
    @extend .button;


  }
}

button.default.form_button_submit {
  @extend .button;
}

.form_button_submit:focus {
  background-color: white !important;
}

select {
  border: 0.0625rem solid $blue !important;
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}
