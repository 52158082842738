.button-group {
  @include zero;
  @include spacing(2);
}

.button {
  display: inline-block;
  padding: 1rem 1.875rem;
  border: 0.0625rem solid;
  border-radius: 0.1875rem;
  color: $black;
  font-family: $sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.1667;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }
}

.button--white {
  color: $white;
}

.button--teal {
  border-color: transparent;
  background: $teal;
  color: $white;
}

.button--overlay {
  border-color: transparent;
  background: $white;
}

.button--text {
  padding: 0;
  border: 0;
  color: $blue;

  &:hover {
    text-decoration: underline;
  }
}

.button--full {
  @include spacing;
  display: block;
  width: 100%;
  text-align: center;
}
