.header-container {
  position: relative;
  z-index: 4;
}

.header {
  padding: 1rem 0;
  background: $white;
  box-shadow: 0 0 0.375rem $black-shadow;

  @include mq(48em) {
    padding: 1.5rem 0;
  }

  .nav-permanent & {
    @include mq(64.0625em) {
      padding: 0;
    }
  }
}

.header--nav {
  @include mq(64.0625em) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  @include mq(72em) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @include mq(76em) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.header__logo {
  width: 10rem;
  max-width: calc(100% - 6.5rem);
  // margin-top: 0.6875rem;
  float: left;
  color: inherit;
  overflow: hidden;
  color: $blue;
  @include mq(48em) {
    // width: 18.75rem;
    // margin-top: 0.5rem;
  }

  .header--nav & {
    @include mq(64.0625em) {
      position: absolute;
      top: 50%;
      width: 15rem;
      max-width: calc(100% - 51rem);
      margin-top: 0;
      transform: translateY(-50%);
    }
    @include mq(72em) {
      max-width: calc(100% - 54rem);
    }
    @include mq(76em) {
      max-width: calc(100% - 57rem);
    }
  }

  .nav-permanent & {
    @include mq(64.0625em) {
      display: none;
    }
  }
}

.header__toggle {
  width: 2.5rem;
  margin-top: 0.35rem;
  margin-left: 0.5rem;
  padding: 0.625rem;
  float: right;
  border-radius: 0.1875rem;
  background: $blue;
  color: $white;

  .header--nav & {
    @include mq(64.0625em) {
      display: none;
    }
  }

  .lemberg & {
    margin-top: 1.35rem;
  }


  &:focus {
    background: $blue !important;
  }
}

.header__toggle--inactive {
  display: block;
}

.header__toggle--active {
  display: none;
}

.header__toggle--search {
  .search-active & {
    .header__toggle--inactive {
      display: none;
    }

    .header__toggle--active {
      display: block;
    }
  }
}

.header__toggle--menu {
  .lemberg & {
    @include mq(64.0625em) {
      display: none;
    }
  }
  .menu-active & {
    .header__toggle--inactive {
      display: none;
    }

    .header__toggle--active {
      display: block;
    }
  }
}
