.overlay {
  position: relative;
}

.overlay--video {
  width: 100%;
  height: auto;
  overflow: hidden;
  @include mq(48em) {
    height: 600px;
  }
}

.overlay__image, .overlay__video, .overlay__video__media {
  width: 100%;
}

.overlay__video {
  width: 100%;
  max-height: 768px;
}

.overlay__wrap {
  @include zero;
}

.overlay__container {
  @include zero;
  margin: 0 -1rem;
  padding: 2.5rem 1rem;
  background: $beige;
  text-align: center;

  @include mq(48em) {
    position: absolute;
    top: 50%;
    width: 100%;
    max-width: calc(100% - 4rem);
    margin: 0;
    padding: 0;
    transform: translateY(-50%);
    background: transparent;
    color: $white;
  }

  @include mq(90em) {
    width: 84rem;
    max-width: calc(100% - 6rem);
  }
}

.overlay__title {
  @include mq(48em) {
    color: $white;
    text-shadow: .1rem .1rem .1rem $black;
  }
}

.overlay__text {
  font-family: $sans-serif;
  font-size: 1.25rem;

  @include mq(48em) {
    font-size: 1.75rem;
    text-shadow: .1rem .1rem .1rem $black;
  }
}

.overlay__button {
  @include mq(48em) {
    border-color: $white;
    background: $white;
  }
}
