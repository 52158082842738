$modal-overlay-background-color: #000 !default;
$modal-overlay-background-alpha: 0.75 !default;
$modal-overlay-background: rgba($modal-overlay-background-color, $modal-overlay-background-alpha);
$modal-video-background: #000 !default;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: $modal-overlay-background;
}

.modal__container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.modal__content {

}

.modal__status {
  text-align: center;
}

.modal__item {
  display: block;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.modal__video {
  position: relative;
  padding-bottom: 56.25%;
  background: $modal-video-background;
}

.modal__embed {
  position: absolute;
  width: 100%;
  height: 100%;
}

.modal__controls {

}

.modal__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.modal__nav--previous {
  left: 0;
}

.modal__nav--next {
  right: 0;
}

.modal__close {
  position: absolute;
  top: 0;
  right: 0;
}

// customizations

.modal {
  z-index: 5;
}

.modal__container {
  max-width: 90rem;
  margin: auto;
  padding: 3rem 1rem;
  @include mq(48em) {
    padding: 3rem 2rem;
  }
  @include mq(90em) {
    padding: 3rem;
  }
}

.modal__overlay,
.modal__status,
.modal__controls,
.modal__close {
  transition: opacity 320ms ease;
  opacity: 0;
}

.modal--active .modal__overlay,
.modal--active .modal__controls,
.modal--active .modal__close,
.modal--loading .modal__status,
.modal--loaded .modal__status {
  opacity: 1;
}

.modal__item {
  transform: scale(0.75);
  transition: opacity 320ms ease, transform 320ms ease;
  opacity: 0;
}

.modal--loaded .modal__item {
  transform: scale(1);
  opacity: 1;
}

.modal__close {
  @include fill($svgstore--close, $white);
  width: 3rem;
  height: 3rem;
  background-position: center;
  background-size: 1rem 1rem;
  text-indent: -999rem;
  overflow: hidden;
}

.modal__nav--icon {
  display: block;
  width: 3rem;
  color: $white;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="0" /><feOffset dx="1" dy="1" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,1)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
  filter: drop-shadow(0 .1rem .1rem $black);
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#000,strength=0);
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background: rgba($black, .54);
  }
}

.modal__caption {
  display: block;
  color: $white;
  margin-top: 1rem;
  font-size: 1.25rem;
}
