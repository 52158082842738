.accordion-group {
  @include zero;
  @include spacing(2);
}

.accordion {
  position: relative;
  overflow: hidden;
  @include spacing;
}

.accordion__heading__toggle {
  font-size: 1.0625rem;
  margin: 0;
  line-height: 1.7059;
}

.accordion__toggle,
.accordion__content {
  padding: 1.25rem;
  background: $beige;
}

.accordion__toggle {
  display: block;
  color: $black;
  font-family: $sans-serif;

  &:hover {
    text-decoration: none;
  }
}

.accordion__icon {
  width: 1.8125rem;
  margin-left: 1.25rem;
  padding: 0.125rem;
  float: right;
}

.accordion__icon--inactive {
  display: block;

  .accordion--active & {
    display: none;
  }
}

.accordion__icon--active {
  display: none;

  .accordion--active & {
    display: block;
  }
}

.accordion__content {
  @include hide;
  @include zero;
  top: 0;
  margin-top: 0.125rem;
  visibility: hidden;
  @extend .p--small;

  .accordion--active & {
    @include show;
    visibility: visible;
  }

  a:not([class]) {
    font-weight: 600;
    border-bottom: 1px solid $blue;
    padding-bottom: .125em;
    &:hover {
      border-color: $teal;
      text-decoration: none;
      color: $teal;
    }
  }
}

.accordion__heading {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.3333;
}

.accordion__expand {
  text-align: right;
  display: block;
  font-family: $sans-serif;
  font-weight: 600;
}
