.card {
  padding: 1.25rem 1.25rem 2.5rem;
  background: $white;
  color: $gray-dark;
  font-family: $sans-serif;
}

.card--alt {
  background: $white;
  color: $gray-dark;
  font-family: $sans-serif;
  padding: 1.25rem 0;
}

.card__header {
  margin-bottom: 1.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 0.25rem solid $gray-dark;
}

.card__meta {
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.card__title {
  font-weight: 600;
  margin-bottom: 0;
}
