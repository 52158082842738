.widget {
  @include zero;
  @include spacing(2.8);
  p {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.8;
  }
  a:not([class]) {
    font-weight: 600;
    border-bottom: 1px solid $blue;
    padding-bottom: .125em;
    &:hover {
      border-color: $teal;
      text-decoration: none;
      color: $teal;
    }
  }
}

.widget--small {
  @include spacing(2);
}

.widget__heading {
  margin-bottom: 0.75rem;
  padding-top: 0.75rem;
  border-top: 0.25rem solid $purple;
  font-family: $sans-serif;
  font-size: 0.9375rem;
  font-weight: 600;
}

.widget__heading--small {
  padding-top: 0;
  border-top: 0;
}

.widget__social {
  @include clear;
  @include spacing;
  padding: 0;
  list-style: none;
}

.widget__social__item {
  margin: 0 1rem 0 0;
  float: left;
}

.widget__social__link {
  display: block;
  width: 2rem;
  color: $gray;
  &:focus {
    outline-offset: 0;
  }
}
