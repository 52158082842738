.media {
  @include zero;
  @include spacing(2);
}

.media--narrow {
  @include mq(48em) {
    max-width: 66.6667%;
  }
}

.media--left,
.media--right {
  @include mq(48em) {
    width: calc(50% - 1rem);
  }
}

.media--left {
  @include mq(48em) {
    margin-right: 2rem;
    float: left;
  }
}

.media--right {
  @include mq(48em) {
    margin-left: 2rem;
    float: right;
  }
}

.media--tight {
  @include spacing(-2);
  @include mq(64.0625em) {
    @include spacing(2);
  }
}

.media__text {
  @include zero;
  @include spacing;
}

.media__item {
  display: inline-block;
  position: relative;
  &:focus{
    outline: 0.125rem solid $focus;
    outline-offset: 0;
  }
  .media--full & {
    display: block;
  }
}

.media__overlay {
  @include zero;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 1.25rem;
  transform: translateY(-50%);
}

.media__overlay--alt {
  @include zero;
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem;
}

.media__overlay__text {
  color: $white;
  font-family: $sans-serif;
  font-weight: 600;
  text-align: center;
  text-shadow: .1rem .1rem .1rem $black;
}

.media__icon {
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem;
  width: 6rem;
  background-image: radial-gradient(24px at 2.9rem 40% , $white 0%, $white 22px, rgba(255, 255, 255, 0) 19px, rgba(255, 255, 255, 0) 19px);
  color: $black;
  .media--full & {
    display: block;
    position: static;
    margin: auto;
    color: $black;
  }
  .image-slider--full & {
    width: 6rem;
    background-image: radial-gradient(24px at 3rem 40% , $white 0%, $white 22px, rgba(255, 255, 255, 0) 19px, rgba(255, 255, 255, 0) 19px);
  }
  .feature__media & {
    width: 5rem;
    background-image: radial-gradient(16px at 2.5rem 40% , $white 0%, $white 18px, rgba(255, 255, 255, 0) 18px, rgba(255, 255, 255, 0) 19px);
  }
  .feed__image--caption & {
    width: 5rem;
    bottom: 2.5rem;
    left: 1.25rem;
  }

  .feed__image--no-caption & {
    width: 5rem;
    bottom: 0;
    left: 1.25rem;
  }
}

.media__credit {
  margin-top: -0.75rem;
}

.media__embed {
  position: relative;
  padding-bottom: 56.25%;
  > * {
    position: absolute;
    width: 100%;
    height: 100%;

  }

}
.jw-skin-bekle.jwplayer:not(.jw-flag-touch):not(.jw-error):not(.jw-state-error):not(.jw-state-buffering) .jw-display-icon-container:focus {
  background-color: #139ed5 !important;
}
.jw-skin-bekle:not(.jw-flag-touch) .jw-button-color:hover, .jw-skin-bekle .jw-button-color:focus {
  background-color: #139ed5 !important;
}
