@import '../../node_modules/sanitize.css/lib/sanitize.scss';

:root {
  font-size: 100%; // temp sanitize.css bug fix
  overflow-x: hidden;
  word-wrap: break-word;
}

body :focus {
  @include focus;
}

*,
::before,
::after {
  scroll-margin-top: 3rem;

  .nav-fixed & {
    @include mq(64.0625em) {
      scroll-margin-top: 8rem;
    }
  }
}


input {
  &[type='radio'] {
    border: 0.0625rem solid $gray-dark;
    border-radius: 0.5rem;
    background-color: $white;

    &:checked {
      background: $black-fade;
    }
  }

  &[type='search'] {
    appearance: none;
  }
}

button {
  cursor: pointer;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  position: relative;
  &:before {
    content: ' ';
  }
  &:after {
    content: attr(alt);
    color: $white;
    display: block;
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
  }
}

svg:not(:root) {
  display: inherit;
}

&::-webkit-media-controls {
  display: none !important;
}
