.faculty__listing__heading__first {
  margin-top: 0;
}

.faculty__listing {
  @include zero;
  @include spacing(1);
  border-bottom: .0625rem solid $gray-dark;
  padding: 1.25rem 0;
  p:not([class]) {
    @extend .p--small;
  }
}

.faculty__listing__image {
  width: 75px;
  height: 75px;
  float: right;
  margin: 0 0 1rem 1rem;
  @include mq(48em) {
    width: 145px;
    height: 146px;
    margin: 0 0 1rem 1rem;
  }
  @include mq(64em) {
    width: 170px;
    height: 170px;
  }
}


.faculty__listing__name {
  font-size: 1.625rem;
  line-height: 1.2308;
  font-weight: 500;

}

.faculty__listing__title,
.faculty__listing__contact,
.faculty__listing__hours,
.faculty__listing__info {
  margin-top: 0.25rem;
  margin-bottom: 0rem;
  font-size: 0.9375rem;
  line-height: 1.8;
}

.faculty__listing__contact,
.faculty__listing__hours {
  font-family: $sans-serif;
  span:not(:last-child):after {
    content:"\a";
    white-space: pre;
    @include mq(48em) {
      content: ' |';
    }
  }
}

.faculty__listing__email {
  font-weight: 600;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.faculty__listing__info {

  font-family: $sans-serif;
  a {
    font-weight: 600;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
