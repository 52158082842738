body {
  font-family: $serif;
  font-size: 1.0625rem;
  line-height: 1.7059;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $black-light;
  font-family: $sans-serif;

  a {
    display: inline-block;
  }
}

h1,
.h1 {
  margin-top: 5rem;
  margin-bottom: 2.5rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.0952;
  @include mq(32em) {
    font-size: 2.625rem;
  }
}

h2,
.h2 {
  margin-top: 5rem;
  margin-bottom: 1.25rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
}

h3,
.h3 {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.2857;
}

h4,
.h4 {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.2308;
}

h5,
.h5 {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.3636;
}

h6,
.h6 {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.3333;
}

.heading--light {
  font-weight: 500;
  a {
    font-weight: 500;
  }
}

strong {
  font-weight: 600;
}


p {
  @include spacing;
  .main & {
    a:not([class]) {
      border-bottom: 1px solid $blue;
      padding-bottom: .125em;
      &:hover {
        border-color: $teal;
        text-decoration: none;
        color: $teal;
      }
    }
  }
}

.p--small {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.8;
}

.p--large {
  font-family: $sans-serif;
  font-size: 1.125rem;
  line-height: 1.8333;
  font-weight: 600;
}

.p--micro {
  font-size: 0.75rem !important;
}

.p--sans-serif {
  font-family: $sans-serif;
}

.p--xlarge {
  font-size: 1.75rem;
  font-weight: 300;
}

a {
  color: $blue;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
}

blockquote {
  @include zero;
  @include spacing;
  padding: 1.25rem;
  background: $beige;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-top: 0.0625rem solid $gray-dark;
  & + .block {
    @include spacing(2);
  }
}

ul,
ol {
  @include zero;
  @include spacing;
  padding-left: 1.25rem;
}

li {
  margin-bottom: 0.75rem;
}

li:not([class]) {
  a:not([class]) {
    font-weight: 600;
    border-bottom: 1px solid $blue;
    padding-bottom: .125em;
    &:hover {
      border-color: $teal;
      text-decoration: none;
      color: $teal;
    }
  }
}

.caption {
  @extend .p--small;
  @extend .p--sans-serif;
}


.inline-image {
  img {
    display: inline-block;
  }
  a {
    padding: 0.25rem;
    display: inline-block;
  }
}

.large--serif {
  font-size: 1.375rem;
  font-weight: lighter;
  line-height: 1.7333;
  font-family: $serif;

}

.two__column__list {
  ul,
  ol {
    @include mq(64em) {
      column-count: 2;
      column-gap: 3rem;
    }
  }
}

.callout {
  @include zero;
  @include spacing;
  padding: 1.25rem;
  background: $beige;
  border-top: .25rem solid #8c2287;
}
