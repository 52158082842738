.breadcrumbs-title {
  margin-bottom: 0.25rem;

  a {
    color: inherit;
  }
}

.breadcrumbs {
  display: none;
  font-family: $sans-serif;
  font-size: 0.75rem;
  font-weight: 400;

  @include mq(64.0625em) {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: -0.25rem !important;
    .block__text & {
      margin-bottom: 0 !important;
    }
  }
}

.breadcrumbs__item {
  color: $blue !important;
  font-weight: 400;
}

.breadcrumbs__item--active {
  font-weight: 600;
  color: $black !important;
}
