.footer {
  background: $gray-dark;
  color: $white;
  font-family: $sans-serif;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;

  p {
    @include spacing(0.5);
  }

  a {
    color: inherit;
    font-weight: 400;
  }
  .button {
    font-weight: 600;
  }
}

.footer__top {
  padding-top: 5rem;
  padding-bottom: 5rem;
  border-bottom: 0.125rem solid $gray-medium;
}

.footer__bottom {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.footer__container {
  @include zero;
}

.footer__heading {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
  line-height: 1.125;
}

.footer__hr {
  @include spacing(0.5);
  border-top: 0.25rem solid $gray-medium;

  .footer__heading + & {
    margin-top: -0.75rem;
  }
}

.footer__nav {
  @include zero;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__nav__item {
  @include spacing(0.5);
}

.footer__nav__link {

}

.footer__social {
  @include clear;
  margin: 1.25rem 0 0;
  padding: 0;
  list-style: none;

  .footer__hr + & {
    margin-top: 0;
  }
}

.footer__social__item {
  margin: 0 1.25rem 0 0;
  float: left;
}

.footer__social__link {
  display: block;
  width: 1.25rem;
  &:focus {
    outline-offset: 0;
  }
}

.footer__text {
  a {
    text-decoration: underline;
  }
}

.footer__logo {
  display: block;
  max-width: 8rem;
}
